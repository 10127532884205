.app__landing {
  min-height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: url(../assets/bg.png);
  background-size: cover;
  background-position: center;
  background-repeat: repeat;
  background-attachment: fixed;
  padding: 4rem;
}
.landing__img {
  max-width: 100%;
}
